$input-width: 200px

=mobile
    @media (max-width: 400px)
        @content

.dialog-form

    .fields
        display: inline-block
        margin: 0 auto
        text-align: left

    .field
        margin-bottom: 20px

    label
        float: left
        display: inline-block
        width: 142px
        vertical-align: middle
        text-align: right

        padding-top: 4px
        padding-right: 1em

        +mobile
            float: none
            text-align: left

    .controls
        white-space: nowrap
        margin-left: 150px
        +mobile
            margin-left: 0


    input[type="text"], input[type="password"], input[type="email"]
        width: $input-width

    .error
        input[type="text"], input[type="password"], input[type="email"]
            border-color: #c4443b

    .required-field-marker
        width: 20px
        color: #c4443b


    .submit-div
        text-align: center
        margin-bottom: 1em

    input[type="submit"]
        font-size: 120%

        color: #fff
        border: none
        background: #f0662f
        &:hover
            background: lighten(#f0662f, 10)

        padding:
            top: 6px
            bottom: 6px
        margin:
            top: 5px
            // bottom: 20px
        cursor: pointer

    .error-text, .help-text
        margin-left: 150px
        font-size: 80%
    .error-text
        color: #c4443b

    &.error .help-text
        display: none

    .errors
        color: #c4443b

    .clear
        clear: both

    .password-with-eye
        input
            width: $input-width - 29px

        .eye-button
            display: inline-block
            width: 28px
            height: 27px
            line-height: 27px
            border: 1px solid #b9b5b2
                left: none
            text-align: center
            vertical-align: bottom
            //background: #ccc
            cursor: pointer
            user-select: none

            background:
                image: url(./eye.svg)
                position: center
                repeat: no-repeat

            &:active
                background-color: #ccc

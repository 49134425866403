$brand_code: 'zont'
$brand_colors__main: #2ababe
$brand_colors__controls_accent: #2ababe
$brand_colors__header__background: #f0f0f0
$brand_colors__header__top_border: #a6ce3a
$brand_colors__header__help_background: #a6ce3a
$brand_colors__header__events_notifier__inactive: #cccccc
$brand_colors__header__events_notifier__highlight: #fe6021
$brand_colors__header__events_notifier__highlight_mobile: #fe6021
$brand_colors__header__events_notifier__number: #ffffff
$brand_colors__header__upgrades_notifier_highlight: #2ababe
$brand_colors__header__link: #888888
$brand_colors__header__title: #444444
$brand_colors__header__hover: yes
$brand_colors__header__logo__width: 131px
$brand_colors__header__logo__height: 45px
$brand_colors__header__logo__narrow__width: 56px
$brand_colors__header__logo__narrow__height: 20px
$brand_colors__helper_background: #1565C0
$brand_colors__leftmenu__header: #0070b7
$brand_colors__leftpanel__font_size: 140%
$brand_colors__cornernotice__header: #0070b7
$brand_colors__app__splash__background: #2ababe
$brand_colors__app__splash__backgroundGradient: #15797c
$brand_colors__app__splash__backgroundDark: #6ac8cc
$brand_colors__app__android__statusbar: #263238
$brand_colors__app__selected_bottom_tabs: rgba(#000, 0.25)
$brand_colors__app__header: #37474f


@import ../mui/constants

// $button-height: 25px
$button-background: #eceaeb
$button-border: $cyan
$button-text-color: #3f3f3f
$button-disabled-border: #b7b6b2
$button-disabled-background: #fff
$button-disabled-text-color: #b9b5b2
$button-active-text-color: #ffffff


button:not([class*="MuiButtonBase-root"]):not([class*="react"]), input[type="submit"], .mui_button
    // height: $button-height
    display: inline-block
    background: $button-background

    border: 2px solid $button-border
    &.warning
        border-color: $red

    padding: 1px 16px
    margin: 0
    text-decoration: none
    cursor: default

    color: $button-text-color

    &:hover
        color: $button-text-color

    &:active, &.mui_button_active
        background: $button-border
        color: $button-active-text-color
        &.warning
            background: $red

    &[disabled="1"], &[disabled="disabled"], &:disabled
        border-color: $button-disabled-border
        background: $button-disabled-background
        color: $button-disabled-text-color
        &:active
            background: $button-disabled-background

    &::-moz-focus-inner
        border: 0

    &:active, &:focus
        outline: none

$brand_code: 'zont'
$brand_colors__main: #2ababe
$brand_colors__controls_accent: #2ababe
$brand_colors__header__background: #f0f0f0
$brand_colors__header__top_border: #a6ce3a
$brand_colors__header__help_background: #a6ce3a
$brand_colors__header__events_notifier__inactive: #cccccc
$brand_colors__header__events_notifier__highlight: #fe6021
$brand_colors__header__events_notifier__highlight_mobile: #fe6021
$brand_colors__header__events_notifier__number: #ffffff
$brand_colors__header__upgrades_notifier_highlight: #2ababe
$brand_colors__header__link: #888888
$brand_colors__header__title: #444444
$brand_colors__header__hover: yes
$brand_colors__header__logo__width: 131px
$brand_colors__header__logo__height: 45px
$brand_colors__header__logo__narrow__width: 56px
$brand_colors__header__logo__narrow__height: 20px
$brand_colors__helper_background: #1565C0
$brand_colors__leftmenu__header: #0070b7
$brand_colors__leftpanel__font_size: 140%
$brand_colors__cornernotice__header: #0070b7
$brand_colors__app__splash__background: #2ababe
$brand_colors__app__splash__backgroundGradient: #15797c
$brand_colors__app__splash__backgroundDark: #6ac8cc
$brand_colors__app__android__statusbar: #263238
$brand_colors__app__selected_bottom_tabs: rgba(#000, 0.25)
$brand_colors__app__header: #37474f


$fonts-root: 'fonts/'


@font-face
    font-family: SegoeUI
    src: url($fonts-root + 'SegoeUI-Regular-subset.eot')
    src: local('Segoe UI'), url($fonts-root + 'SegoeUI-Regular-subset.woff') format('woff'), url($fonts-root + 'SegoeUI-Regular-subset.ttf')  format('truetype'), url($fonts-root + 'SegoeUI-Regular-subset.svg#SegoeUI') format('svg')
    font-weight: normal
    font-style: normal

@font-face
    font-family: SegoeUI
    src: url($fonts-root + 'SegoeUI-Light-subset.eot')
    src: local('Segoe UI Light'), url($fonts-root + 'SegoeUI-Light-subset.woff') format('woff'), url($fonts-root + 'SegoeUI-Light-subset.ttf')  format('truetype'), url($fonts-root + 'SegoeUI-Light-subset.svg#SegoeUI-Light') format('svg')
    font-weight: 100
    font-style: normal

@font-face
    font-family: SegoeUI
    src: url($fonts-root + 'SegoeUI-Semibold-subset.eot')
    src: local('Segoe UI Semibold'), url($fonts-root + 'SegoeUI-Semibold-subset.woff') format('woff'), url($fonts-root + 'SegoeUI-Semibold-subset.ttf')  format('truetype'), url($fonts-root + 'SegoeUI-Semibold-subset.svg#SegoeUI-Semibold') format('svg')
    font-weight: bold
    font-style: normal

@font-face
    font-family: SegoeUI
    src: url($fonts-root + 'SegoeUI-Italic-subset.eot')
    src: local('Segoe UI Italic'), local('Segoe UI'), url($fonts-root + 'SegoeUI-Italic-subset.woff') format('woff'), url($fonts-root + 'SegoeUI-Italic-subset.ttf')  format('truetype'), url($fonts-root + 'SegoeUI-Italic-subset.svg#SegoeUI-Italic') format('svg')
    font-weight: normal
    font-style: italic

$brand_code: 'zont'
$brand_colors__main: #2ababe
$brand_colors__controls_accent: #2ababe
$brand_colors__header__background: #f0f0f0
$brand_colors__header__top_border: #a6ce3a
$brand_colors__header__help_background: #a6ce3a
$brand_colors__header__events_notifier__inactive: #cccccc
$brand_colors__header__events_notifier__highlight: #fe6021
$brand_colors__header__events_notifier__highlight_mobile: #fe6021
$brand_colors__header__events_notifier__number: #ffffff
$brand_colors__header__upgrades_notifier_highlight: #2ababe
$brand_colors__header__link: #888888
$brand_colors__header__title: #444444
$brand_colors__header__hover: yes
$brand_colors__header__logo__width: 131px
$brand_colors__header__logo__height: 45px
$brand_colors__header__logo__narrow__width: 56px
$brand_colors__header__logo__narrow__height: 20px
$brand_colors__helper_background: #1565C0
$brand_colors__leftmenu__header: #0070b7
$brand_colors__leftpanel__font_size: 140%
$brand_colors__cornernotice__header: #0070b7
$brand_colors__app__splash__background: #2ababe
$brand_colors__app__splash__backgroundGradient: #15797c
$brand_colors__app__splash__backgroundDark: #6ac8cc
$brand_colors__app__android__statusbar: #263238
$brand_colors__app__selected_bottom_tabs: rgba(#000, 0.25)
$brand_colors__app__header: #37474f


@import ../common/common
@import ../mui/constants
@import homepage
@import minipage-background


html
    @extend .minipage-background

    height: auto
    min-height: 100%

    display: flex
    justify-content: center
    align-items: center

    *
        box-sizing: border-box



body
    position: relative
    max-width: 630px
    padding: 35px 20px 35px
    margin: 20px 10px
    @media (min-width: 1024px)
        padding: 35px 65px 35px
    background: #fff
    height: auto
    +box-shadow(0 0 30px rgba(0, 0, 0, 0.4))

    font-size: 16px

    border-top: 6px solid $cyan

    &.narrow
        max-width: 480px



h1
    font-size: 175%
    color: $cyan
    font-weight: 100
    margin-bottom: 1em
    margin-top: 1em
    &:first-child
        margin-top: 0
h2
    font-size: 140%
    color: $cyan
    font-weight: 100
    margin-bottom: 0.5em
h3
    font-size: 120%
    color: $cyan
    font-weight: 100
    margin-bottom: 0.5em



hr
    margin: 40px 0
    border: none
        top: 1px solid #999


p
    margin-bottom: 20px


li
    margin-bottom: 1.0em


tt
    font:
        family: monospace
        size: 90%
    // border: 1px dashed #c0c0c0
    background: #e8e8e8
    padding: 0 3px



.warning
    color: #c4443b


.home-link
    color: #aaa
    display: block
    margin-bottom: 1em

.minipage-background
    background-color: #999
    @media (prefers-color-scheme: dark)
        background-color: #333

    &:before
        content: ' '
        position: absolute
        z-index: -2
        left: 0
        top: 0
        right: 0
        bottom: 0
        background-image: url(./backgrounds/minipage-bg-#{$brand_code}.svg)
        @media (prefers-color-scheme: dark)
            opacity: 0.7

.zont.minipage-background
    &:before
        background: linear-gradient(0, $brand_colors__app__splash__backgroundGradient, $brand_colors__app__splash__background)

.baxi.minipage-background
    &:before
        background: linear-gradient(0, $brand_colors__app__splash__background, $brand_colors__app__splash__backgroundGradient)

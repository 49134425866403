$brand_code: 'zont'
$brand_colors__main: #2ababe
$brand_colors__controls_accent: #2ababe
$brand_colors__header__background: #f0f0f0
$brand_colors__header__top_border: #a6ce3a
$brand_colors__header__help_background: #a6ce3a
$brand_colors__header__events_notifier__inactive: #cccccc
$brand_colors__header__events_notifier__highlight: #fe6021
$brand_colors__header__events_notifier__highlight_mobile: #fe6021
$brand_colors__header__events_notifier__number: #ffffff
$brand_colors__header__upgrades_notifier_highlight: #2ababe
$brand_colors__header__link: #888888
$brand_colors__header__title: #444444
$brand_colors__header__hover: yes
$brand_colors__header__logo__width: 131px
$brand_colors__header__logo__height: 45px
$brand_colors__header__logo__narrow__width: 56px
$brand_colors__header__logo__narrow__height: 20px
$brand_colors__helper_background: #1565C0
$brand_colors__leftmenu__header: #0070b7
$brand_colors__leftpanel__font_size: 140%
$brand_colors__cornernotice__header: #0070b7
$brand_colors__app__splash__background: #2ababe
$brand_colors__app__splash__backgroundGradient: #15797c
$brand_colors__app__splash__backgroundDark: #6ac8cc
$brand_colors__app__android__statusbar: #263238
$brand_colors__app__selected_bottom_tabs: rgba(#000, 0.25)
$brand_colors__app__header: #37474f


@import ./constants


html, body
    height: 100%
    margin: 0
    position: relative

body
    font-family: SegoeUI, Segoe UI, Trebuchet MS, Helvetica, Tahoma, Arial, Verdana, sans-serif
    font-size: $font-size
    color: #222

    line-height: 1.3

input, textarea, select, button
    font-family: SegoeUI, Segoe UI, Trebuchet MS, Helvetica, Tahoma, Arial, Verdana, sans-serif
    font-size: 100%
    max-width: 100%


h1, h2, h3, h4, h5
    margin-top: 0
    //color: #242424
    font-weight: normal


label
    user-select: none
    -o-user-select: none
    -moz-user-select: none
    -khtml-user-select: none
    -webkit-user-select: none
    cursor: pointer


a
    color: $cyan
    text-decoration: underline

    &:hover, &:focus, &.mui_link_hover
        color: $orange

@import forms

ul, ol
    padding-left: 30px



.login-form
    text-align: center

    line-height: 1.4

    input[type="text"], input[type="password"]
        margin-bottom: 10px
        font-size: 120%
        width: 200px
        text-align: inherit

        padding: 7px
        border:
            width: 1px
            color: #c0c0c0
            radius: 5px
            style: solid

        &:focus
            padding: 6px
            border:
                width: 2px
                color: $brand_colors__controls_accent


    .login-eye-button
        display: inline-block
        width: 30px
        text-align: center
        vertical-align: bottom
        cursor: pointer
        user-select: none
        right: 30px
        z-index: 10
        position: absolute
        height: 41px

        background:
            image: url(eye.svg)
            position: center
            repeat: no-repeat

        &:active
            background-color: $brand_colors__controls_accent
            height: 41px
            border-radius: 0 5px 5px 0

    .input-with-placeholder
        position: relative

    .error
        input[type="text"], input[type="password"]
            border-color: #c4443b

    input[type="submit"], a.demo
        font-size: 130%

        color: #fff
        border: none
        border-radius: 3px
        background: $brand_colors__controls_accent
        &:hover
            background: lighten($brand_colors__controls_accent, 10)

        padding:
            top: 5px
            bottom: 5px
            left: 40px
            right: 40px
        margin:
            top: 5px
            bottom: 10px
        cursor: pointer
        -webkit-appearance: none

    a.demo
        display: inline-block
        text-decoration: none
        font-size: 100%
        margin-bottom: 20px
        border: 1px solid $brand_colors__controls_accent
        background: #fff
        color: $brand_colors__controls_accent

        &:hover
            background: rgba($brand_colors__controls_accent, 0.1)

    .error-message
        color: #c4443b
        margin-bottom: 5px


    .register-link
        font-size: 120%
        line-height: 2

    .forgot-link
        line-height: 2
        a
            color: #888



.register-form
    width: 380px

    color: #777

    h1
        text-align: center
        margin-bottom: 30px



.messages-dialog
    width: 400px
    color: #444

    &.success
        color: #282

    &.error
        color: #c4443b

    p
        margin-bottom: 0.7em



.forgot-form
    .error
        color: #c4443b
        input[type="text"], input[type="password"]
            border-color: #c4443b

    .forgot-form-found-users
        list-style-type: none
        padding: 0 20px
        margin: 0
        line-height: 2

        li
            float: left
            padding: 10px 0
            a
                padding: 20px




.new-password-form
    h1
        text-align: center

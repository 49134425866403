$brand_code: 'zont'
$brand_colors__main: #2ababe
$brand_colors__controls_accent: #2ababe
$brand_colors__header__background: #f0f0f0
$brand_colors__header__top_border: #a6ce3a
$brand_colors__header__help_background: #a6ce3a
$brand_colors__header__events_notifier__inactive: #cccccc
$brand_colors__header__events_notifier__highlight: #fe6021
$brand_colors__header__events_notifier__highlight_mobile: #fe6021
$brand_colors__header__events_notifier__number: #ffffff
$brand_colors__header__upgrades_notifier_highlight: #2ababe
$brand_colors__header__link: #888888
$brand_colors__header__title: #444444
$brand_colors__header__hover: yes
$brand_colors__header__logo__width: 131px
$brand_colors__header__logo__height: 45px
$brand_colors__header__logo__narrow__width: 56px
$brand_colors__header__logo__narrow__height: 20px
$brand_colors__helper_background: #1565C0
$brand_colors__leftmenu__header: #0070b7
$brand_colors__leftpanel__font_size: 140%
$brand_colors__cornernotice__header: #0070b7
$brand_colors__app__splash__background: #2ababe
$brand_colors__app__splash__backgroundGradient: #15797c
$brand_colors__app__splash__backgroundDark: #6ac8cc
$brand_colors__app__android__statusbar: #263238
$brand_colors__app__selected_bottom_tabs: rgba(#000, 0.25)
$brand_colors__app__header: #37474f


@import ../common/common

.mui_basedialog
    input[type="text"], input[type="password"], input[type="email"], input[type="search"], input[type="number"], input[type="tel"], textarea
        border: 1px solid #b9b5b2
        padding: 3px 5px
        // margin: 2px

        width: 150px

        background-color: #fff
        color: #222
        @include transition(0.2s border-color)

        outline: none

        &.error, &:invalid:not(.ignore-invalid)
            border-color: #c4443b

        &:disabled
            background: #eceaeb
            color: #b9b5b2

        &:focus
            border-color: #7192be
            +transition(0.1s border-color)
            +box-shadow(0 0 3px rgba(#7192be, 0.4) inset)

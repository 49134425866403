=vendor($name, $value)
    -webkit-#{$name}: $value
    -moz-#{$name}: $value
    -ms-#{$name}: $value
    -o-#{$name}: $value
    #{$name}: $value


=transition-transform($value)
    -webkit-transition: -webkit-transform $value
    -moz-transition:    -moz-transform    $value
    -ms-transition:     -ms-transform     $value
    -o-transition:      -o-transform      $value
    transition:         transform         $value


=user-select-none
    +vendor(user-select, none)


=round-corners($radius: 5px)
    +vendor(border-radius, $radius)
=border-radius($radius: 5px)
    +vendor(border-radius, $radius)


=transition($trans)
    +vendor(transition, $trans)

=transition-property($prop)
    +vendor(transition-property, $prop)

=transition-timing-function($func)
    +vendor(transition-timing-function, $func)

=transition-delay($func)
    +vendor(transition-delay, $func)

=transition-duration($func)
    +vendor(transition-duration, $func)

=transform($trans)
    +vendor(transform, $trans)

=transform-origin($origin)
    +vendor(transform-origin, $origin)

=box-shadow($shadow)
    +vendor(box-shadow, $shadow)


=box-sizing($sizing)
    +vendor(box-sizing, $sizing)

=background-size($bsize)
    +vendor(background-size, $bsize)


=keyframes($name)
    @-webkit-keyframes #{$name}
        @content
    @-moz-keyframes #{$name}
        @content
    @-o-keyframes #{$name}
        @content
    @keyframes #{$name}
        @content

=animation($params)
    +vendor(animation, $params)


=retina
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)
        @content

=webkit-only
    @media screen and (-webkit-min-device-pixel-ratio: 0)
        @content



=clearfix
    zoom: 1

    &:before, &:after
        content: "."
        visibility: hidden
        display: block
        height: 0
        overflow: hidden

    &:after
        clear: both



=vertical-center
    position: relative
    white-space: nowrap

    &:before
        content: ''
        display: inline-block
        height: 100%
        vertical-align: middle
        white-space: normal

    & > *
        display: inline-block
        vertical-align: middle
        text-align: center
        white-space: normal

$brand_code: 'zont'
$brand_colors__main: #2ababe
$brand_colors__controls_accent: #2ababe
$brand_colors__header__background: #f0f0f0
$brand_colors__header__top_border: #a6ce3a
$brand_colors__header__help_background: #a6ce3a
$brand_colors__header__events_notifier__inactive: #cccccc
$brand_colors__header__events_notifier__highlight: #fe6021
$brand_colors__header__events_notifier__highlight_mobile: #fe6021
$brand_colors__header__events_notifier__number: #ffffff
$brand_colors__header__upgrades_notifier_highlight: #2ababe
$brand_colors__header__link: #888888
$brand_colors__header__title: #444444
$brand_colors__header__hover: yes
$brand_colors__header__logo__width: 131px
$brand_colors__header__logo__height: 45px
$brand_colors__header__logo__narrow__width: 56px
$brand_colors__header__logo__narrow__height: 20px
$brand_colors__helper_background: #1565C0
$brand_colors__leftmenu__header: #0070b7
$brand_colors__leftpanel__font_size: 140%
$brand_colors__cornernotice__header: #0070b7
$brand_colors__app__splash__background: #2ababe
$brand_colors__app__splash__backgroundGradient: #15797c
$brand_colors__app__splash__backgroundDark: #6ac8cc
$brand_colors__app__android__statusbar: #263238
$brand_colors__app__selected_bottom_tabs: rgba(#000, 0.25)
$brand_colors__app__header: #37474f


// not() selector is to block IE8
\:not(#foo)
    input[type="radio"]
        position: absolute
        opacity: 0
        z-index: 1
        margin: 0
            top: 1px

        cursor: pointer

        & + span.mui_radiobutton
            background: url(./images/radiobutton.svg) 0 0 no-repeat

            position: absolute
            display: inline-block

            width: 14px
            height: 14px

            margin:
                top: 3px
                right: 4px

            & + label
                padding-left: 18px
                cursor: pointer

        &:checked + span.mui_radiobutton
            background-position: 0 -14px
        &:disabled + span.mui_radiobutton
            background-position: 0 -28px
        &:disabled:checked + span.mui_radiobutton
            background-position: 0 -42px
